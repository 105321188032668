const projects = [
  {
    id: 1,
    title: "Swap Goes to School",
    description:
      '"Menghubungkan Masa Depan, Membangun Pendidikan": Program ini mengedukasi generasi muda di daerah terpencil dengan pengetahuan tentang growing personality, pendidikan tinggi, kesehatan seksual, sosial, dan lingkungan, memastikan mereka mendapatkan kesempatan yang sama untuk masa depan yang lebih cerah.',
    image: "swap.JPG",
  },
  {
    id: 2,
    title: "Donasi Senyum",
    description:
      '"Setiap Donasi Adalah Senyuman Baru": Platform donasi yang menghubungkan kebaikan hati dengan mereka yang membutuhkan bantuan, terutama para korban bencana di seluruh Indonesia.',
    image: "donasisemeru.JPG",
  },
  {
    id: 3,
    title: "Ramadhan Kareem",
    description:
      '"Berkah untuk Semua, Kebaikan yang Terus Menyala": Program sosial yang mengulurkan tangan bagi mereka yang membutuhkan—tunawisma, disabilitas, lansia, dan masyarakat kurang mampu—dengan semangat berbagi setiap bulan Ramadhan.',
    image: "ramadhankareem.png",
  },
  {
    id: 4,
    title: "Sosial Campaign",
    description:
      '"Bersama Peduli, Bersama Menginspirasi": Kampanye yang menggugah kesadaran publik untuk peduli pada isu sosial, pendidikan, dan lingkungan, menanamkan nilai-nilai SWAP di hati masyarakat.',
    image: "sosialcampaign.png",
  },
];

export default projects;
