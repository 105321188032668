const testimonis = [
  {
    id: 1,
    name: "Krisviani",
    message:
      "Makasih sudah menyediakan tempat untuk mempermudah menyalurkan bantuan kepada orang yang membutuhkan, karena ga semua orang bisa turun langsung buat membantu Jadi adanya organisasi ini bener bener mempermudah buat orang yang ingin melakukan kebaikan tapi gak bisa turun langsung 👍🏻. Semoga terus bermanfaat buat semua orang ya",
    image: "krisviani.png",
  },
  {
    id: 2,
    name: "Ibu Yayuk",
    message:
      "Alhamdulillah dengan adanya komunitas organisasi swap yg bergerak dalam sosial sungguh luar biasa berdampak positif banget bagi kami Pondok Panti Lansia Mbah Putri Kendalrejo Blitar  dimana bisa sedikit meringankan beban buat kami dan sudah sangat terbantu .. semoga Swap tetap kompak ,solid, membawa keberkahan buat semuanya 🤲🏻🤲🏻🙏",
    image: "buyayuk.png",
  },
  {
    id: 3,
    name: "Dr. Vina",
    message:
      'Dikenalkan SWAP "Smart With A Purpose Project" dari dek Novi, dan Alhamdulillah apa yang kami donasikan benar-benar dapat tersalurkan dan amanah. Jadi barang-barang kita yang masih bisa terpakai bisa bermanfaat untuk orang lain daripada nganggur di lemari dan dihisab. Dan program donasi lainnya juga bisa banget tuh, daripada bingung 2.5% pendapatan kita mau dikemanakan, bisa banget dimanfaatkan untuk sesama.  Karena jujur, di sekitar kita ini masih banyak banget orang yang kekurangan, kalau bukan kita, siapa yang akan membantu mereka.',
    image: "dr-vina.png",
  },
];

export default testimonis;
